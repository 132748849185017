export const convertQsNumber = (
  queryVariable: string | string[] | null | undefined,
  defaultValue: string
) => {
  if (queryVariable) {
    if (Array.isArray(queryVariable)) {
      return parseInt(queryVariable[0] || defaultValue, 10);
    }
    return parseInt(queryVariable || defaultValue, 10);
  }

  return parseInt(defaultValue, 10);
};
