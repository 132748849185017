import { useMutation } from "@apollo/client";
import { GOLDWARE_ME } from "@app/api/GoldwareMe.graphql";
import Loading from "@app/components/Loading/Loading";
import { TOKEN_KEY } from "@app/constants";
import { useSearch } from "@app/hooks/useSearch";
import { SLACK_LOGIN } from "@app/pages/SlackLogin/SlackLogin.graphql";
import { useAuthContext } from "@app/provider/MyAuthProvider";
import { Routes } from "@app/router/Routes";
import { Goldware_MeQuery } from "@app/types/generated";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const SlackLogin = () => {
  const { onSignin, onError } = useAuthContext();
  const [slackLogin, { client }] = useMutation(SLACK_LOGIN);
  const history = useHistory();
  const { code } = useSearch();

  useEffect(() => {
    if (code) {
      const login = async () => {
        try {
          const { data } = await slackLogin({
            variables: {
              code,
            },
          });
          const token = data?.slackGoldwareUserLogin?.token;
          if (token) {
            sessionStorage.setItem(TOKEN_KEY, token);
            const { data: responseData } = await client.query<Goldware_MeQuery>(
              {
                query: GOLDWARE_ME,
              }
            );
            if (responseData?.goldwareMe) {
              await onSignin({
                token: token,
                user: responseData?.goldwareMe,
              });
              history.push({
                pathname: Routes.Home,
              });
            }
          }
        } catch (err) {
          onError(err);
        }
      };
      login();
    } else {
      history.push(Routes.Home);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loading />;
};

export default SlackLogin;
