import { useQuery } from "@apollo/client";
import MyError from "@app/components/MyError/MyError";
import MyModalLink from "@app/components/MyModalLink/MyModalLink";
import MyPagination from "@app/components/MyPagination/MyPagination";
import { usePagination } from "@app/hooks/usePagination";
import { SC_NEWS_CONNECTION } from "@app/pages/ScNews/ScNews.graphql";
import { Routes } from "@app/router/Routes";
import {
  Sc_News_ConnectionQuery,
  Sc_News_ConnectionQueryVariables,
} from "@app/types/generated";
import { Button, PageHeader, Table } from "antd";
import React from "react";

export const useScNewsVariables = (): Sc_News_ConnectionQueryVariables => {
  const { skip, first } = usePagination();

  return {
    skip,
    first,
  };
};

const ScNews = () => {
  const variables = useScNewsVariables();
  const { loading, error, data } = useQuery<
    Sc_News_ConnectionQuery,
    Sc_News_ConnectionQueryVariables
  >(SC_NEWS_CONNECTION, {
    variables,
  });

  if (error) return <MyError error={error} />;

  const dataSource = data?.scNewsesConnection?.edges?.map((edge) => ({
    key: edge?.node?.id,
    title: edge?.node?.title,
    link: edge?.node?.link,
  }));

  const total = data?.total?.aggregate?.count || 0;

  return (
    <div>
      <PageHeader
        title={"Oshirase"}
        extra={
          <MyModalLink to={Routes.NoticeCreate}>
            <Button>Add notice</Button>
          </MyModalLink>
        }
      />
      <Table
        columns={[
          {
            dataIndex: "title",
            title: "タイトル",
          },
          {
            dataIndex: "link",
            title: "リンク",
          },
        ]}
        dataSource={dataSource}
        loading={loading}
        pagination={false}
      />
      <MyPagination total={total} />
    </div>
  );
};

export default ScNews;
