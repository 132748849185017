import { ModalCompProps } from "@app/router/MyModalRoute";
import { Modal } from "antd";
import React, { FC } from "react";

const PlanDetail: FC<ModalCompProps> = ({ closeModal }) => {
  return (
    <Modal visible onCancel={closeModal}>
      <div>PlanDetail</div>
    </Modal>
  );
};

export default PlanDetail;
