import { usePagination } from "@app/hooks/usePagination";
import { useSearch } from "@app/hooks/useSearch";
import { qsStringify } from "@app/utils/qsStringify";
import { Pagination } from "antd";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";

interface MyPaginationProps {
  total: number;
}

const MyPagination: FC<MyPaginationProps> = ({ total }) => {
  const search = useSearch();
  const history = useHistory();
  const { skip, first } = usePagination();

  return (
    <div style={{ textAlign: 'center', padding: 20 }}>
      <Pagination
        pageSize={first}
        current={Math.floor(skip / first) + 1}
        total={total}
        onChange={(page, pageSize) => {
          history.push({
            search: qsStringify({
              ...search,
              page,
              pageSize,
            }),
          });
        }}
      />
    </div>
  );
};

export default MyPagination;
