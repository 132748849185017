import { useSearch } from "@app/hooks/useSearch";
import { convertQsNumber } from "@app/utils/convertQsNumber";

interface PaginationArgs {
  defaultSize: number;
}

interface PaginationVariables {
  skip: number;
  first: number;
}

export const usePagination = (args?: PaginationArgs): PaginationVariables => {
  const { defaultSize } = args || {};
  const search = useSearch();
  const { page, pageSize } = search;

  const first = convertQsNumber(pageSize, `${defaultSize || 20}`);
  const pageNumber = convertQsNumber(page, "1");
  const skip = first * (pageNumber - 1);

  return {
    first,
    skip,
  };
};
