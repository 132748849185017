import React, { createContext, FC, useContext } from "react";
import {
  split,
  ApolloProvider,
  InMemoryCache,
  ApolloClient,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
import { TOKEN_KEY } from "@app/constants";
import { createUploadLink } from "apollo-upload-client";
import { useAuthContext } from "@app/provider/MyAuthProvider";
const MyApolloContext = createContext<{}>({});

export const MyApolloProvider: FC = ({ children }) => {
  const { token } = useAuthContext();
  const httpLink = createUploadLink({
    uri: window._env_.HTTP_ENDPOINT,
  });

  const wsLink = new WebSocketLink({
    uri: window._env_.WS_ENDPOINT,
    options: {
      reconnect: true,
      connectionParams: {
        authorization: token,
      },
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const authLink = setContext(async (_, { headers }) => {
    const TOKEN = await sessionStorage.getItem(TOKEN_KEY);
    return {
      headers: {
        ...headers,
        authorization: TOKEN ? `Bearer ${TOKEN}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(splitLink),
    cache: new InMemoryCache(),
  });

  return (
    <MyApolloContext.Provider value={{}}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </MyApolloContext.Provider>
  );
};

export const useMyApolloContext = () => useContext(MyApolloContext);
