import { gql } from "@apollo/client";

export const SC_NEWS_CONNECTION = gql`
  query SC_NEWS_CONNECTION($where: ScNewsWhereInput, $first: Int, $skip: Int) {
    scNewsesConnection(where: $where, first: $first, skip: $skip, orderBy: createdAt_DESC) {
      edges {
        node {
          id
          title
          link
          content
        }
      }
    }
    total: scNewsesConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
