export const Routes = {
  Home: "/",
  Root: "/app",
  Login: "/login",
  SlackLogin: "/slack_login",
  Users: "/app/users",
  Plans: "/app/plans",
  Notices: "/app/notices",
  PlanDetail: "/:planId",
  NoticeCreate: "/news_create",
  NoticeUpdate: "/news_detail/:newsId",
};
