import React from "react";
import { MyAuthProvider } from "@app/provider/MyAuthProvider";
import { MyApolloProvider } from "@app/provider/MyApolloProvider";
import Router from "@app/router/Router";

function App() {
  return (
    <MyAuthProvider>
      <MyApolloProvider>
        <Router />
      </MyApolloProvider>
    </MyAuthProvider>
  );
}

export default App;
