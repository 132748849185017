import React, { FC } from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";

const MyModalLink: FC<{ to: string }> = ({ to, children }) => {
  const location = useLocation();
  const match = useRouteMatch();

  return (
    <Link
      to={{
        pathname: `${match.url}${to}`,
        search: location.search,
      }}
    >
      {children}
    </Link>
  );
};

export default MyModalLink;
