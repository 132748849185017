import { KeyOutlined, UserOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { GOLDWARE_ME } from "@app/api/GoldwareMe.graphql";
import { TOKEN_KEY } from "@app/constants";
import { LOGIN } from "@app/pages/Login/Login.graphql";
import { useAuthContext } from "@app/provider/MyAuthProvider";
import { Routes } from "@app/router/Routes";
import { popup } from "@app/tools/popup";
import {
  Goldware_MeQuery,
  LoginMutation,
  LoginMutationVariables,
} from "@app/types/generated";
import { Button, Checkbox, Form, Input, Typography } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

interface LoginValues {
  email: string;
  password: string;
}

const Login = () => {
  const history = useHistory();
  const { onSignin } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<LoginValues>();
  const [login, { client }] = useMutation<
    LoginMutation,
    LoginMutationVariables
  >(LOGIN);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const onFinish = async (values: LoginValues): Promise<void> => {
    try {
      setLoading(true);
      const { data } = await login({
        variables: {
          email: values.email,
          password: values.password,
        },
      });
      setLoading(false);
      const token = data?.goldwareLogin?.token;
      if (token) {
        sessionStorage.setItem(TOKEN_KEY, token);
        const { data: responseData } = await client.query<Goldware_MeQuery>({
          query: GOLDWARE_ME,
        });
        if (responseData?.goldwareMe) {
          await onSignin({
            token: token,
            user: responseData?.goldwareMe,
          });
          history.push({
            pathname: Routes.Home,
          });
        }
      }
    } catch (error) {
      popup.error(error);
      setLoading(false);
    }
  };

  const hostname = window.location.origin;
  const CLIENT_ID = window._env_.SLACK_CLIENT_ID;

  return (
    <div className="login-container">
      <div className="login-inner">
        <Typography.Title>Member login</Typography.Title>
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input prefix={<UserOutlined />} />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password prefix={<KeyOutlined />} />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              Submit
            </Button>
          </Form.Item>
          <a
            href={`https://slack.com/oauth/v2/authorize?user_scope=identity.basic&client_id=${CLIENT_ID}&redirect_uri=${hostname}/slack_login`}
          >
            <img
              src="https://api.slack.com/img/sign_in_with_slack.png"
              alt="slack"
            />
          </a>
        </Form>
      </div>
    </div>
  );
};

export default Login;
