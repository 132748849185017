import { ApolloError } from "@apollo/client";
import { notification } from "antd";

export const popup = {
  success: (description: string) => {
    notification.success({
      message: "成功",
      description,
    });
  },

  error: (arg: string | ApolloError) => {
    let description;
    if (typeof arg === "string") {
      description = arg;
    } else if (arg.networkError) {
      description = "ネットワークエラー";
    } else if (arg.graphQLErrors) {
      description = arg.graphQLErrors?.[0]?.message;
    }
    notification.error({
      message: "エラー",
      description,
    });
  },
};
