import { ApolloError } from "@apollo/client";
import NetworkError from "@app/components/NetworkError/NetworkError";
import React, { FC } from "react";

const MyError: FC<{ error: ApolloError }> = ({ error }) => {
  if (error.networkError) {
    return <NetworkError />;
  }
  return (
    <div>
      {error.graphQLErrors?.map(({ message }) => {
        return <div key={message}>{message}</div>;
      })}
    </div>
  );
};

export default MyError;
