import { gql } from '@apollo/client'

export const USER_FRAGMENT = gql`
  fragment USER_FRAGMENT on User {
    id
    createdAt
    name
    nameKana
    email
    createDataBaseStatus
    employeesCount
    databaseName
    isActive
    isCancel
    monthlySubscriptions(orderBy: end_DESC) {
      end
      plan {
        id
        name
      }
    }
  }
`

export const USERS_CONNECTION = gql`
  query USERS_CONNECTION($where: UserWhereInput, $skip: Int, $first: Int) {
    usersConnection(
      where: $where
      skip: $skip
      first: $first
      orderBy: createdAt_DESC
    ) {
      edges {
        node {
          ...USER_FRAGMENT
        }
      }
    }
    total: usersConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${USER_FRAGMENT}
`

export const ACCEPT_USER_REQUEST = gql`
  mutation ACCEPT_USER_REQUEST($userId: String!) {
    createDatabase(userId: $userId)
  }
`

export const CANCEL_SUBSCRIPTION = gql`
  mutation CANCEL_SUBSCRIPTION($userId: String!) {
    cancelSubscription(userId: $userId)
  }
`
