import { gql } from "@apollo/client";

export const GOLDWARE_USER_FRAGMENTS = gql`
  fragment GOLDWARE_USER_FRAGMENTS on GoldwareUser {
    id
    email
    name
  }
`;

export const GOLDWARE_ME = gql`
  query GOLDWARE_ME {
    goldwareMe {
      ...GOLDWARE_USER_FRAGMENTS
    }
  }
  ${GOLDWARE_USER_FRAGMENTS}
`;
