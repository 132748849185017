import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import MyModalLink from "@app/components/MyModalLink/MyModalLink";
import { Button, Col, Row, Typography } from "antd";
import React, { FC } from "react";

interface Plan {
  title: string;
  price: string;
  items: {
    size: number;
    unit: string;
    description: string;
    notIncluded?: boolean;
  }[];
}

const ListItem: FC<{
  notIncluded?: boolean;
  description: string;
}> = ({ notIncluded, description }) => {
  return (
    <li className="plan-list-item">
      <span className="plan-list-icon">
        {notIncluded ? (
          <CloseOutlined style={{ color: "red", fontWeight: 900 }} />
        ) : (
          <CheckOutlined style={{ color: "green", fontWeight: 900 }} />
        )}
      </span>
      {description}
    </li>
  );
};

const PlanItem: FC<{ plan: Plan }> = ({ plan }) => {
  return (
    <div className="plan-item-container">
      <Typography.Title level={3}>{plan.title}</Typography.Title>
      <Typography.Title level={2}>{plan.price}</Typography.Title>
      <div className="plan-item-body">
        <ul className="plan-list">
          {plan.items.map((item, index) => {
            return (
              <ListItem
                key={`${index}`}
                description={`${item.size ? `${item.size}${item.unit}` : ""} ${
                  item.description
                }`}
                notIncluded={item.notIncluded}
              />
            );
          })}
        </ul>
      </div>
      <div className="plan-item-footer">
        <MyModalLink to={`/${plan.title}`}>
          <Button block size="large" type="primary">
            Edit
          </Button>
        </MyModalLink>
      </div>
    </div>
  );
};

const Plans = () => {
  const plans: Plan[] = [
    {
      title: "スタート",
      price: "無料",
      items: [
        {
          size: 5,
          unit: "GB",
          description: "ファイルアップロード上限",
        },
        {
          size: 100,
          unit: "件/人",
          description: "マイタスク件数",
        },
        {
          size: 500,
          unit: "件/会社",
          description: "取引先、アタックリスト件数",
        },
        {
          size: 3,
          unit: "件",
          description: "グループ種別",
        },
        {
          size: 3,
          unit: "件",
          description: "社員種別",
        },
        {
          size: 0,
          notIncluded: true,
          unit: "件",
          description: "シフト管理",
        },
      ],
    },
    {
      title: "スタンダード",
      price: "3,000円（税抜）",
      items: [
        {
          size: 30,
          unit: "GB",
          description: "ファイルアップロード上限",
        },
        {
          size: 1000,
          unit: "件/人",
          description: "マイタスク件数",
        },
        {
          size: 3000,
          unit: "件/会社",
          description: "取引先、アタックリスト件数",
        },
        {
          size: 20,
          unit: "件",
          description: "グループ種別",
        },
        {
          size: 20,
          unit: "件",
          description: "社員種別",
        },
        {
          size: 0,
          notIncluded: false,
          unit: "件",
          description: "シフト管理",
        },
      ],
    },
    {
      title: "プレミアム",
      price: "10,000円（税抜）",
      items: [
        {
          size: 150,
          unit: "GB",
          description: "ファイルアップロード上限",
        },
        {
          size: 10000,
          unit: "件/人",
          description: "マイタスク件数",
        },
        {
          size: 10000,
          unit: "件/会社",
          description: "取引先、アタックリスト件数",
        },
        {
          size: 20,
          unit: "件",
          description: "グループ種別",
        },
        {
          size: 20,
          unit: "件",
          description: "社員種別",
        },
        {
          size: 0,
          notIncluded: false,
          unit: "件",
          description: "シフト管理",
        },
      ],
    },
  ];

  return (
    <Row gutter={[24, 8]}>
      {plans.map((item) => {
        return (
          <Col lg={8} key={item.title}>
            <PlanItem plan={item} />
          </Col>
        );
      })}
    </Row>
  );
};

export default Plans;
