import { useAuthContext } from "@app/provider/MyAuthProvider";
import { Routes } from "@app/router/Routes";
import React from "react";
import { Redirect } from "react-router-dom";

const HomeRedirect = () => {
  const { user } = useAuthContext();
  if (user) return <Redirect to={Routes.Users} />;
  return <Redirect to={Routes.Login} />;
};

export default HomeRedirect;
