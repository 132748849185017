import { useAuthContext } from "@app/provider/MyAuthProvider";
import { ModalRouteProps, MyModalRoute } from "@app/router/MyModalRoute";
import { Routes } from "@app/router/Routes";
import React from "react";
import { FC } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

export interface MyRouteProps extends RouteProps {
  isProtected?: boolean;
  path: string;
  component: FC<any>;
  modals?: ModalRouteProps[];
}

const MyRoute: FC<MyRouteProps> = ({
  isProtected,
  path,
  component: Component,
  modals,
}) => {
  const { user } = useAuthContext();

  return (
    <Route
      path={path}
      render={(routeProps) => {
        if (isProtected) {
          if (user)
            return (
              <React.Fragment>
                <Component {...routeProps} />
                {modals?.map((modal) => {
                  return (
                    <MyModalRoute
                      key={modal.path}
                      path={modal.path}
                      component={modal.component}
                    />
                  );
                })}
              </React.Fragment>
            );
          return <Redirect to={Routes.Home} />;
        }
        return <Component {...routeProps} />;
      }}
    />
  );
};

export default MyRoute;
