import PlanDetail from "@app/pages/Plans/PlanDetail/PlanDetail";
import Plans from "@app/pages/Plans/Plans";
import ScNews from "@app/pages/ScNews/ScNews";
import ScNewsCreate from "@app/pages/ScNews/ScNewsCreate/ScNewsCreate";
import Users from "@app/pages/Users/Users";
import { MyRouteProps } from "@app/router/MyRoute";
import { Routes } from "@app/router/Routes";

export const routeConfig: MyRouteProps[] = [
  {
    path: Routes.Users,
    isProtected: true,
    component: Users,
  },
  {
    path: Routes.Plans,
    isProtected: true,
    component: Plans,
    modals: [
      {
        path: Routes.PlanDetail,
        component: PlanDetail,
      },
    ],
  },
  {
    path: Routes.Notices,
    isProtected: true,
    component: ScNews,
    modals: [
      {
        path: Routes.NoticeCreate,
        component: ScNewsCreate,
      },
    ],
  },
];
