import { Result } from "antd";
import React from "react";

const NetworkError = () => {
  return (
    <div className='container'>
      <Result status="500" title="ネトワークエラー" />
    </div>
  );
};

export default NetworkError;
