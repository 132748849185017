import { useMutation, useQuery } from '@apollo/client'
import { Formik } from 'formik'
import MyError from '@app/components/MyError/MyError'
import {
  ACCEPT_USER_REQUEST,
  CANCEL_SUBSCRIPTION,
  USERS_CONNECTION,
} from '@app/pages/Users/Users.graphql'
import {
  Users_ConnectionQueryVariables,
  Accept_User_RequestMutation,
  Accept_User_RequestMutationVariables,
} from '@app/types/generated'
import { formatDate } from '@app/utils/formatDate'
import { Button, PageHeader, Table, Tag, Tooltip } from 'antd'
import React from 'react'
import { popup } from '@app/tools/popup'
import { usePagination } from '@app/hooks/usePagination'
import MyPagination from '@app/components/MyPagination/MyPagination'
import { Moment } from 'moment'

export const useUsersVariables = (): Users_ConnectionQueryVariables => {
  const { first, skip } = usePagination()

  return {
    first,
    skip,
  }
}

const Users = () => {
  const variables = useUsersVariables()
  const { loading, error, data } = useQuery(USERS_CONNECTION, {
    variables,
  })
  const [acceptUserRequest] = useMutation<
    Accept_User_RequestMutation,
    Accept_User_RequestMutationVariables
  >(ACCEPT_USER_REQUEST)
  const [cancelSubscription] = useMutation<
    Accept_User_RequestMutation,
    Accept_User_RequestMutationVariables
  >(CANCEL_SUBSCRIPTION)

  const statusColors = {
    REJECTED: 'error',
    REQUESTED: 'processing',
    CREATED: 'success',
    PAYMENT_METHOD_REQUIRED: 'warning',
  }

  const statusTranslation = {
    REJECTED: '却下',
    REQUESTED: '確認待ち',
    CREATED: '作成済み',
    PAYMENT_METHOD_REQUIRED: '要支払方法',
  }

  enum StatusColorENUM {
    REJECTED = 'REJECTED',
    REQUESTED = 'REQUESTED',
    CREATED = 'CREATED',
    PAYMENT_METHOD_REQUIRED = 'PAYMENT_METHOD_REQUIRED',
  }

  if (error) return <MyError error={error} />

  const userAction = ({
    status,
    nodeId,
    isCancel,
  }: {
    status: string
    nodeId: string
    isCancel: boolean
  }) => {
    if (status === 'REQUESTED') {
      return (
        <Formik
          initialValues={{ crateDatabase: false }}
          onSubmit={async (values, { setFieldValue }) => {
            try {
              if (nodeId) {
                await acceptUserRequest({
                  variables: {
                    userId: nodeId,
                  },
                })
              }
              setFieldValue('crateDatabase', true)
              popup.success(
                'データベース作成完了までしばらく時間がかかります。'
              )
            } catch (err) {
              popup.error(err)
            }
          }}
        >
          {(formikProps) => {
            const { isSubmitting, handleSubmit, values } = formikProps
            return (
              <div>
                <Button
                  type="primary"
                  loading={isSubmitting}
                  onClick={() => handleSubmit()}
                  disabled={values.crateDatabase}
                >
                  データベース作成
                </Button>
              </div>
            )
          }}
        </Formik>
      )
    }
    if (status === 'CREATED' && !isCancel) {
      return (
        <Formik
          initialValues={{ crateDatabase: false }}
          onSubmit={async (values, { setFieldValue }) => {
            try {
              if (nodeId) {
                await cancelSubscription({
                  variables: {
                    userId: nodeId,
                  },
                })
              }
              popup.success('退会処理を実行しました。')
            } catch (err) {
              popup.error(err)
            }
          }}
        >
          {(formikProps) => {
            const { isSubmitting, handleSubmit, values } = formikProps
            return (
              <div>
                <Button
                  loading={isSubmitting}
                  onClick={() => handleSubmit()}
                  disabled={values.crateDatabase}
                >
                  退会
                </Button>
              </div>
            )
          }}
        </Formik>
      )
    }
  }

  const paymentStatus = ({ status }: { status: StatusColorENUM }) => {
    if (status === 'PAYMENT_METHOD_REQUIRED') {
      return (
        <Tooltip title="ユーザーが支払方法を更新すると自動的にデータベースを作成します。">
          <Tag color={statusColors[status]}>{statusTranslation[status]}</Tag>
        </Tooltip>
      )
    }
    return <Tag color={statusColors[status]}>{statusTranslation[status]}</Tag>
  }

  const dataSource: {
    index: number
    name: string
  }[] =
    data?.usersConnection?.edges?.map(
      (
        edge: {
          node: {
            id: string
            createDataBaseStatus: StatusColorENUM
            monthlySubscriptions: {
              plan: { name: string }
              end: string | Moment | undefined
            }[]
            name: string
            nameKana: string
            employeesCount: number
            createdAt: string | Moment | undefined
            isActive: boolean
            isCancel: boolean
          }
        },
        index: number
      ) => {
        const nodeId = edge?.node?.id
        const status = edge?.node?.createDataBaseStatus
        const planName = edge?.node.monthlySubscriptions[0].plan.name
        const endOfMonthlySubscription = edge?.node.monthlySubscriptions[0]?.end
        return {
          key: nodeId,
          index: index + 1,
          name: edge?.node?.name || '',
          nameKana: edge?.node?.nameKana || '',
          employeesCount: edge?.node?.employeesCount,
          plan: <Tag color="success">{planName}</Tag>,
          status: status && paymentStatus({ status }),
          userStatus: !edge?.node?.isActive && <Tag color="error">退会</Tag>,
          startDate: formatDate(edge?.node?.createdAt),
          endDate: formatDate(endOfMonthlySubscription),
          action: userAction({
            status,
            nodeId,
            isCancel: edge?.node?.isCancel,
          }),
        }
      }
    ) || []

  const total = data?.total?.aggregate?.count || 0

  return (
    <div>
      <PageHeader title={'Users'} />
      <Table
        pagination={false}
        columns={[
          {
            dataIndex: 'index',
            title: 'NO',
          },
          {
            dataIndex: 'name',
            title: '会社名',
          },
          {
            dataIndex: 'nameKana',
            title: '会社名カナ',
          },
          {
            dataIndex: 'employeesCount',
            title: '従業員数',
          },
          {
            dataIndex: 'plan',
            title: 'プラン',
          },
          {
            dataIndex: 'status',
            title: 'データベース作成状況',
          },
          {
            dataIndex: 'startDate',
            title: '登録日時',
          },
          {
            dataIndex: 'userStatus',
            title: 'ユーザー状況',
          },
          {
            dataIndex: 'endDate',
            title: '月額プラン終了日(予定日)',
          },
          {
            dataIndex: 'action',
            title: 'アクション',
          },
        ]}
        dataSource={dataSource}
        loading={loading}
        scroll={{ x: true }}
      />
      <MyPagination total={total} />
    </div>
  )
}

export default Users
