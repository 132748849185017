import { ApolloError } from "@apollo/client";
import { TOKEN_KEY } from "@app/constants";
import { Goldware_User_FragmentsFragment } from "@app/types/generated";
import React, { createContext, FC, useContext, useState } from "react";

// TO DO: needs to be fixed later when back-end User is defined.
interface MyAuthState {
  loading: boolean;
  error: null | ApolloError;
  token: null | string;
  user: null | Goldware_User_FragmentsFragment;
}

interface OnSignInArgs {
  token: string;
  user: Goldware_User_FragmentsFragment;
}

interface MyAuthContextState extends MyAuthState {
  onSignin: (args: OnSignInArgs) => Promise<void>;
  onSignout: () => Promise<void>;
  onError: (args: ApolloError) => void;
}

const MyAuthContext = createContext<MyAuthContextState>({
  loading: true,
  error: null,
  token: null,
  user: null,
  onSignin: async (args: OnSignInArgs) => {},
  onError: async (args: ApolloError) => {},
  onSignout: async () => {},
});

export const MyAuthProvider: FC = ({ children }) => {
  const [authState, setAuthState] = useState<MyAuthState>({
    loading: true,
    error: null,
    token: null,
    user: null,
  });

  const onSignin = async (args: OnSignInArgs) => {
    await sessionStorage.setItem(TOKEN_KEY, args.token);
    setAuthState({
      loading: false,
      error: null,
      token: args.token,
      user: args.user,
    });
  };

  const onSignout = async () => {
    await sessionStorage.removeItem(TOKEN_KEY);
    setAuthState({
      loading: false,
      error: null,
      token: null,
      user: null,
    });
  };

  const onError = (err: ApolloError) => {
    if (err.graphQLErrors?.length) {
      onSignout();
    } else {
      setAuthState((prevState) => ({
        ...prevState,
        loading: false,
        error: err,
      }));
    }
  };

  const { loading, error, token, user } = authState;

  return (
    <MyAuthContext.Provider
      value={{
        loading,
        error,
        token,
        user,
        onSignin,
        onSignout,
        onError,
      }}
    >
      {children}
    </MyAuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(MyAuthContext);
