import { useMutation } from "@apollo/client";
import { useScNewsVariables } from "@app/pages/ScNews/ScNews";
import { SC_NEWS_CONNECTION } from "@app/pages/ScNews/ScNews.graphql";
import { CREATE_NEWS } from "@app/pages/ScNews/ScNewsCreate/ScNewsCreate.graphql";
import ScNewsForm from "@app/pages/ScNews/ScNewsForm/ScNewsForm";
import { ModalCompProps } from "@app/router/MyModalRoute";
import { popup } from "@app/tools/popup";
import {
  Create_NewsMutation,
  Create_NewsMutationVariables,
} from "@app/types/generated";
import { Form, Modal } from "antd";
import React, { FC } from "react";

const ScNewsCreate: FC<ModalCompProps> = ({ closeModal }) => {
  const variables = useScNewsVariables();
  const [createNews] = useMutation<
    Create_NewsMutation,
    Create_NewsMutationVariables
  >(CREATE_NEWS, {
    refetchQueries: [
      {
        query: SC_NEWS_CONNECTION,
        variables,
      },
    ],
    awaitRefetchQueries: true,
  });
  const [form] = Form.useForm<{
    title: string;
    content: string;
    link: string;
  }>();

  return (
    <Modal
      visible
      onCancel={closeModal}
      title={"News Create"}
      onOk={async () => {
        try {
          const values = await form.validateFields();
          form.resetFields();
          await createNews({
            variables: {
              data: {
                title: values.title,
                content: "",
                link: values.link,
              },
            },
          });
          popup.success("お知らせを作りました");
          closeModal();
        } catch (err) {
          popup.error(err);
        }
      }}
    >
      <ScNewsForm form={form} />
    </Modal>
  );
};

export default ScNewsCreate;
