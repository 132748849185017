import { gql } from "@apollo/client";

export const CREATE_NEWS = gql`
  mutation CREATE_NEWS($data: ScNewsCreateInput!) {
    createScNews(data: $data) {
      id
      title
      content
      link
    }
  }
`;
