import { Menu } from "antd";
import {
  NotificationOutlined,
  PieChartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Routes } from "@app/router/Routes";

const Sidebar = () => {
  const { pathname } = useLocation();

  return (
    <Menu mode="inline" theme="light" selectedKeys={[pathname]}>
      <Menu.Item key={Routes.Users} icon={<UserOutlined />}>
        Users <Link to={Routes.Users} />
      </Menu.Item>
      <Menu.Item key={Routes.Plans} icon={<PieChartOutlined />}>
        Plans <Link to={Routes.Plans} />
      </Menu.Item>
      <Menu.Item key={Routes.Notices} icon={<NotificationOutlined />}>
        News <Link to={Routes.Notices} />
      </Menu.Item>
    </Menu>
  );
};

export default Sidebar;
