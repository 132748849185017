import React, { FC } from "react";
import { Route, useLocation, useRouteMatch } from "react-router-dom";

export interface ModalCompProps {
  closeModal: () => void;
}

export interface ModalRouteProps {
  path: string;
  component: FC<ModalCompProps>;
}

export const MyModalRoute: FC<ModalRouteProps> = ({
  path,
  component: Component,
}) => {
  const { url } = useRouteMatch();
  const { search } = useLocation();

  return (
    <Route
      path={`${url}${path}`}
      render={({ history }) => {
        return (
          <Component
            closeModal={() => {
              history.push({
                pathname: url,
                search: search,
              });
            }}
          />
        );
      }}
    />
  );
};
