import { useApolloClient } from "@apollo/client";
import Loading from "@app/components/Loading/Loading";
import NetworkError from "@app/components/NetworkError/NetworkError";
import NotFound from "@app/components/NotFound/NotFound";
import { useAuthContext } from "@app/provider/MyAuthProvider";
import { Routes } from "@app/router/Routes";
import React, { useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Login from "@app/pages/Login/Login";
import HomeRedirect from "@app/router/HomeRedirect";
import MyLayout from "@app/layout/Layout";
import MyRoute from "@app/router/MyRoute";
import { routeConfig } from "@app/router/routeConfig";
import { GOLDWARE_ME } from "@app/api/GoldwareMe.graphql";
import { TOKEN_KEY } from "@app/constants";
import { Goldware_MeQuery } from "@app/types/generated";
import SlackLogin from "@app/pages/SlackLogin/SlackLogin";

const Router = () => {
  const client = useApolloClient();
  const {
    loading,
    error,
    user,
    onSignin,
    onSignout,
    onError,
  } = useAuthContext();

  useEffect(() => {
    const init = async () => {
      try {
        const token = await sessionStorage.getItem(TOKEN_KEY);
        if (token) {
          const { data } = await client.query<Goldware_MeQuery>({
            query: GOLDWARE_ME,
          });
          if (data?.goldwareMe) {
            await onSignin({
              token: token,
              user: data?.goldwareMe,
            });
          }
        } else {
          await onSignout();
        }
      } catch (err) {
        onError(err);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading />;
  if (error && error.networkError) return <NetworkError />;

  return (
    <BrowserRouter>
      <Switch>
        <Route path={Routes.Home} exact component={HomeRedirect} />
        <Route path={Routes.Login} exact component={Login} />
        <Route path={Routes.SlackLogin} exact component={SlackLogin} />
        <Route
          path={Routes.Root}
          render={() => {
            if (!user) return <Redirect to={Routes.Home} />;
            return (
              <MyLayout>
                <Switch>
                  <Route path={Routes.Root} exact component={HomeRedirect} />
                  {routeConfig.map((routeConfig) => {
                    return (
                      <MyRoute
                        key={routeConfig.path}
                        path={routeConfig.path}
                        component={routeConfig.component}
                        isProtected={routeConfig.isProtected}
                        modals={routeConfig.modals}
                      />
                    );
                  })}
                </Switch>
              </MyLayout>
            );
          }}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
